<template>
    <div id="main-menu">
        <div class="logo d-flex justify-content-center pt-4">
            <img src="@/assets/images/galas_white.png" alt="">
        </div>
        <div class="menu">
            <div class="d-flex flex-column font-bold">
                <router-link :to="{ name: 'search-categories' }"> Catégories </router-link>
                <router-link :to="{ name: 'members' }"> Membres </router-link>
                <router-link :to="{ name: 'jury' }"> Jury </router-link>
                <router-link :to="{ name: 'ballot' }"> Scrutin </router-link>
                <router-link :to="{ name: 'send-emails' }"> Envoi de  courriels </router-link>
                <router-link :to="{ name: 'users' }"> Utilisateurs admin </router-link>
                <router-link :to="{ name: 'public-export' }"> Vote public </router-link>
            </div>
            <div class="ballot-started d-flex justify-content-between py-1 px-3" v-if="ballotStarted">
                <div class="checkbox align-self-center text-center">
                    <img class="align-self-center pt-1" src="@/assets/images/gold_checkmark.png" alt="">
                </div>
                <p class="m-0 font-bold">
                    Les votes sont <br> ouverts
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    computed: {
        ...mapState('core/ballot', [
            'ballotStarted'
        ]),
    }
}
</script>
